import UserAPI from "./API";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies  from 'js-cookie';

export const doLogIn = createAsyncThunk<any, any, any>(
  "users/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.logIn(data);
      return {
        data: response,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doRegister = createAsyncThunk<any, any, any>(
  "users/register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.register(data);
      return {
        data: response,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doLogout = createAsyncThunk<any, any, any>(
  "users/logout",
  async (_, { rejectWithValue }) => {
    try {
      Cookies.remove('UD')
      Cookies.remove('UT')
      Cookies.remove('TYPE')

      const response = await UserAPI.logout();
      return {
        data: response,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doForgotPassword = createAsyncThunk<any, any, any>(
  "users/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.forgotPasswordReq(data);
      return {
        data: response,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doRefresh = createAsyncThunk<any, any, any>(
  "users/refresh",
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserAPI.refresh();
      return {
        data: response,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doResetPassword = createAsyncThunk<any, any, any>(
  "users/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.resetPassword(data);
      return {
        data: response,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doVerifyEmail = createAsyncThunk<any, any, any>(
  "users/verifyEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.verifyEmail(data);
      if (response.data.message !== "success") {
        throw response.data;
      }
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const thunks = {
  doLogIn,
  doVerifyEmail,
  doResetPassword,
  doRefresh,
  doForgotPassword,
  doLogout,
  doRegister,
};

export default thunks;
